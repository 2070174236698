import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import $ from "jquery";
import axios from "axios";
import { useSharedContext } from "../CommonPage/SharedDataSet";

function Mfa() {
  const email = localStorage.getItem("emailUser");
  let type = localStorage.getItem("fileType");
  let auth = localStorage.getItem("auth");
  const [values, setValues] = useState({ mfa: "" });
  const navigate = useNavigate();

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  //   const handleResendMfa = (e) => {
  //     e.preventDefault();
  //     axios
  //       .post(`${process.env.REACT_APP_BASE_URL}sendOtp`, {
  //         email: email,
  //         type,
  //       })
  //       .then((res) => {
  //         toast.success(res.data.message);
  //       })
  //       .catch((error) => {
  //         toast.error(error.data.message);
  //       });
  //   };

  const CheckMFA = async (e) => {
    e.preventDefault();
    try {
      const spinner =
        '<span className="spinner-border spinner-border-sm"></span> Loading.';
      const btns = $("#submitButton");
      btns.html(spinner);
      btns.attr("disabled", true);

      if (!values.mfa) {
        toast.warning("Please fill mfa code.");
        btns.html("submit");
        btns.attr("disabled", false);
      } else {
        const res = await axios.put(
          `${process.env.REACT_APP_BASE_URL}message/verifyOtp`,
          { otp: values.mfa, email: email, type: type, auth: auth }
        );
        if (res?.status == 200) {
          toast.success(res.data.message, { autoClose: 1000 });
          window.location.href = "/user_home";
        }
      }
    } catch (err) {
      toast.error(err.response.data.message, { autoClose: 1000 });
      const btns = $("#submitButton");
      btns.html("submit");
      btns.attr("disabled", false);
    }
  };

  useEffect(() => {
    document.title = "sleap";
  }, []);

  return (
    <>
      <div id="layout" className="theme-blue">
        <div id="wrapper">
          <div className="d-flex h100vh align-items-center auth-main w-100">
            <div className="auth-box">
              <div className="top mb-2">
                <div
                  className="logo"
                  style={{ padding: "0 8px", background: "#fff" }}
                >
                  <img src="logo.png" alt="iSleep" className="img-fluid" />
                </div>
              </div>
              <div className="card shadow p-lg-4">
                <div className="card-header">
                  <p className="fs-5 mb-0  tw-text-white">
                    Multi-Factor Authentication (MFA) verification
                  </p>
                </div>
                <div className="card-body">
                  <p>
                    Please enter your MFA code received in your registered mail.
                  </p>
                  <form action="#">
                    <div className="form-group mb-3">
                      <strong>Enter MFA Code</strong>
                      <div className="input-group">
                        <span className="input-group-text" id="basic-addon20">
                          <i className="fa fa-key"></i>
                        </span>
                        <input
                          type="number"
                          name="mfa"
                          value={values.mfa}
                          max={6}
                          onInput={(e) => {
                            if (e.target.value.length > 6) {
                              e.target.value = e.target.value.slice(0, 6);
                            }
                          }}
                          onChange={handleChange}
                          className="form-control email"
                          placeholder="000000"
                          aria-label="email"
                          aria-describedby="basic-addon20"
                        />
                      </div>
                    </div>
                    <button
                      type="submit"
                      className="btn btn-primary w-100 px-3 py-2"
                      onClick={CheckMFA}
                    >
                      Verify MFA
                    </button>
                    {/* <div className="text-center mt-3">
                      <span className="helper-text">
                        Resend MFA Code?
                        <Link
                          //   onClick={handleResendMfa}
                          className="text-underline"
                        >
                          Click Here
                        </Link>
                      </span>
                      <br />
                    </div> */}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Mfa;
