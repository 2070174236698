import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";

function UserLogin() {
  const [values, setValues] = useState({ email: "" });
  const [type, setType] = useState("");
  const [auth, setAuth] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const param = queryParams.get("type");
  
  useEffect(() => {
    if (param) {
      const myArr = param.split("/");
      const type = myArr[0];
      const auth = myArr[1];
      setType(type);
      setAuth(auth);
    }
  }, [param]);

  useEffect(() => {
    document.title = "sleap: User Verify";
    const rememberMeData = localStorage.getItem("rememberMe");
    if (rememberMeData) {
      const { email, remember } = JSON.parse(rememberMeData);
      setValues((prevValues) => ({ ...prevValues, email }));
    }
  }, []);

  const handleLoginData = async (e) => {
    e.preventDefault();
    console.log(values);
    try {
      const spinner = `<span className="spinner-border spinner-border-sm"></span> Loading.`;
      const btns = document.getElementById("submitButton");
      btns.innerHTML = spinner;
      btns.disabled = true;

      if (values.email === "") {
        toast.warning("Please fill valid email address.");
        btns.innerHTML = "Submit";
        btns.disabled = false;
      } else {
        const res = await axios.put(
          `${process.env.REACT_APP_BASE_URL}message/verifyUser`,
          {
            email: values.email,
            type: type,
            auth: auth,
          }
        );
        console.log("RESPONSE", res.data.message);
        if (res.status == 200) {
          toast.success(res.data.message);
          localStorage.setItem("emailUser", values.email);
          localStorage.setItem("fileType", type);
          localStorage.setItem("auth", auth);
          navigate("/mfa");
        } else {
          navigate("/");
        }
      }
    } catch (err) {
      console.error("Error:", err);
      toast.error(err.response.data.message, { autoClose: 1000 });
      const btns = document.getElementById("submitButton");
      btns.innerHTML = "Submit";
      btns.disabled = false;
    }
  };

  const handleLoginChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  return (
    <>
      <div id="layout" className="theme-blue">
        <div id="wrapper">
          <div className="d-flex h100vh align-items-center auth-main w-100">
            <div className="auth-box">
              <div className="top mb-2">
                <div
                  className="logo"
                  style={{ padding: "0 8px", background: "#fff" }}
                >
                  <img src="logo.png" alt="sleap" className="img-fluid" />
                </div>
              </div>
              <div className="card shadow p-lg-4">
                <div className="card-header">
                  <p className="fs-5 mb-0  tw-text-white">
                    Login to your account
                  </p>
                </div>
                <div className="card-body">
                  <form action="#">
                    <div className="form-group">
                      <strong>Enter E-mail</strong>
                      <div className="input-group">
                        <span className="input-group-text" id="basic-addon20">
                          <i className="fa fa-envelope-o"></i>
                        </span>
                        <input
                          type="text"
                          name="email"
                          value={values.email}
                          onChange={handleLoginChange}
                          className="form-control email"
                          placeholder="example@example.com"
                          aria-label="email"
                          aria-describedby="basic-addon20"
                        />
                      </div>
                    </div>
                    <button
                      type="submit"
                      id="submitButton"
                      onClick={(e) => handleLoginData(e)}
                      className="btn btn-primary w-100 px-3 py-2 mt-3"
                    >
                      SUBMIT
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default UserLogin;
