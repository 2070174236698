import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import $ from "jquery";
import axios from "axios";
import { useSharedContext } from "../CommonPage/SharedDataSet";
// import '../index.css';

function Login() {
  const [values, setValues] = useState({ email: "", password: "" });
  const [rememberMe, setRememberMe] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "sleap: Login";
    // Check localStorage for rememberMe data
    const rememberMeData = localStorage.getItem("rememberMe");
    if (rememberMeData) {
      const { email, remember } = JSON.parse(rememberMeData);
      setValues((prevValues) => ({ ...prevValues, email }));
      setRememberMe(remember);
    }
  }, []);

  const handleLoginData = async (e) => {
    e.preventDefault();
    console.log(values);
    try {
      const spinner = `<span className="spinner-border spinner-border-sm"></span> Loading.`;
      const btns = document.getElementById("submitButton");
      btns.innerHTML = spinner;
      btns.disabled = true;

      if (values.email === "" || values.password === "") {
        // toast.warning("Please fill all fields.");
        btns.innerHTML = "Submit";
        btns.disabled = false;
      } else {
        const res = await axios.post(
          `${process.env.REACT_APP_BASE_URL}user/login`,
          {
            systemLogin: values.email,
            password: values.password,
          }
        );
        console.log("Response:", res);
        if (res.status == 200) {
          toast.success(res.data.message);
          if (res.data.type === "User") {
            sessionStorage.setItem("type", "User");
            sessionStorage.setItem("token", res.data.token);
            window.location.href = "/dashboard";
            // navigate("/dashboard");
          }
          if (res.data.type === "Vendor") {
            sessionStorage.setItem("types", "Vendor");
            sessionStorage.setItem("type", "Vendor");
            sessionStorage.setItem("token", res.data.token);
            window.location.href='/home'
            // navigate("/home");
          }
          // else alert('No type')
          // toast.success(res.data.message, { autoClose: 1000 });
        }
      }
    } catch (err) {
      console.error("Error:", err);
      //   toast.error(err.response.data.message, { autoClose: 1000 });
      const btns = document.getElementById("submitButton");
      btns.innerHTML = "Submit";
      btns.disabled = false;
    }
  };

  const handleRememberMeChange = (e) => {
    setRememberMe(e.target.checked);
  };

  const handleLoginChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  return (
    <>
      <div id="layout" className="theme-blue">
        <div id="wrapper">
          <div className="d-flex h100vh align-items-center auth-main w-100">
            <div className="auth-box">
              <div className="top mb-2">
                <div
                  className="logo"
                  style={{ padding: "0 8px", background: "#fff" }}
                >
                  <img src="logo.png" alt="sleap" className="img-fluid" />
                </div>
              </div>
              <div className="card shadow p-lg-4">
                <div className="card-header">
                  <p className="fs-5 mb-0 tw-text-white">
                    Login to your account
                  </p>
                </div>
                <div className="card-body">
                  <form action="#">
                    <div className="form-group">
                      <strong>Login ID</strong>
                      <div className="input-group">
                        <span className="input-group-text" id="basic-addon20">
                          <i className="fa fa-envelope-o"></i>
                        </span>
                        <input
                          type="text"
                          name="email"
                          value={values.email}
                          onChange={handleLoginChange}
                          className="form-control email"
                          placeholder="example@example"
                          aria-label="email"
                          aria-describedby="basic-addon20"
                        />
                      </div>
                    </div>
                    <div className="form-group mt-3">
                      <strong>Password</strong>
                      <div className="input-group">
                        <span className="input-group-text" id="basic-addon21">
                          <i className="fa fa-key"></i>
                        </span>
                        <input
                          type={showPassword ? "text" : "password"} // Toggle input type based on showPassword state
                          name="password"
                          value={values.password}
                          onChange={handleLoginChange}
                          className="form-control key"
                          placeholder="Password"
                          aria-label="key"
                          aria-describedby="basic-addon21"
                        />
                        <button
                          type="button"
                          className="btn btn-outline-secondary"
                          onClick={togglePasswordVisibility}
                          style={{ height: "36px" }}
                        >
                          {showPassword ? (
                            <i className="fa fa-eye-slash"></i>
                          ) : (
                            <i className="fa fa-eye"></i>
                          )}
                        </button>
                      </div>
                    </div>
                    <div className="form-check my-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={rememberMe}
                        onChange={handleRememberMeChange}
                        id="rememberMeCheckbox"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="rememberMeCheckbox"
                      >
                        Remember me
                      </label>
                    </div>
                    <button
                      type="submit"
                      id="submitButton"
                      onClick={(e) => handleLoginData(e)}
                      className="btn btn-primary w-100 px-3 py-2"
                    >
                      LOGIN
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
